import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import routerView from './views/JWL_RouterView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:lang/handle-login',
    name: 'handle_login',
    component: () => import('common-modules/src/components/CommonHandleLogin.vue'),
  },
  {
    path: '/:lang/login',
    name: 'login',
    component: () => import('common-modules/src/components/CommonLogin.vue'),
  },
  {
    path: '/:lang',
    name: 'lang',
    component: routerView,
    redirect: (to: Route) => ({ name: 'home', lang: to.params.lang }),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('./views/JWL_Home.vue'),
      },
      {
        path: 'preview',
        name: 'preview',
        component: routerView,
        redirect: { name: 'preview_list' },
        children: [
          {
            path: '',
            name: 'preview_list',
            component: () => import('./views/JWL_CoursePreview.vue'),
          },
          {
            path: ':unit',
            name: 'unit_preview_wrapper',
            component: routerView,
            redirect: { name: 'unit_preview' },
            children: [
              {
                path: '',
                name: 'unit_preview',
                component: () => import('./views/JWL_UnitPreview.vue'),
              },
              {
                path: 'wbt/:wbtIndex',
                name: 'wbt_preview',
                component: () => import('./views/WbtPreview.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'course',
        name: 'course',
        component: () => import('./views/LMS_Course.vue'),
        redirect: { name: 'course_home' },
        children: [
          {
            path: ':concentration/list',
            name: 'concentration_home',
            component: () => import('./views/JWL_CourseHome.vue'),
          },
          {
            path: ':concentration/itinerary',
            name: 'concentration_itinerary',
            component: () => import('./views/JWL_Itinerary.vue'),
          },
        ],
      },
      {
        path: 'unit/:unit',
        name: 'unit',
        component: routerView,
        redirect: { name: 'unit_overview' },
        children: [
          {
            path: 'overview',
            name: 'unit_overview',
            component: () => import('./views/JWL_UnitOverview.vue'),
          },
          {
            path: 'wbt/:wbtIndex',
            name: 'unit_wbt',
            component: () => import('./views/JWL_Wbt.vue'),
          },
          {
            path: 'submit-text/:task',
            name: 'submit_text',
            component: () => import('./views/JWL_TaskSubmitText.vue'),
          },
          {
            path: 'submit-work/:task',
            name: 'submit_work',
            component: () => import('./views/JWL_TaskSubmitWork.vue'),
          },
          {
            path: 'discuss/:task',
            name: 'discuss',
            component: () => import('./views/JWL_TaskDiscuss.vue'),
          },
          {
            path: 'quiz/:task',
            name: 'quiz',
            component: () => import('./views/JWL_TaskQuiz.vue'),
          },
        ],
      },
      {
        path: 'class',
        name: 'class_root',
        component: routerView,
        redirect: { name: 'home' },
        children: [
          {
            path: ':concentration/gradebook',
            name: 'class',
            component: () => import('./views/JWL_Gradebook.vue'),
          },
          {
            path: ':course/grade/:task/:lmsId',
            name: 'grade',
            component: () => import('./views/JWL_Grade.vue'),
          },
          {
            path: ':course/discuss/:task/:classroomId',
            name: 'grade_discussion',
            component: () => import('./views/JWL_TaskDiscuss.vue'),
          },
        ],
      },
      {
        path: 'support',
        name: 'support',
        component: () => import('./views/JWL_Support.vue'),
      },
    ],
  },
];

export default new VueRouter({
  mode: 'history',
  routes,
});
