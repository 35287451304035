import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.use(CKEditor);

// Languages supported by this SPA
const languages = ['en', 'ar', 'de'];
// Languages that will redirect to the arabic SPA instead of the english
const arLanguages = ['ar'];
// Languages that have an rtl writing direction
const rtlLanguages = ['ar'];

router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

  if (!to.params.lang || !languages.includes(to.params.lang)) {
    const lang = window.navigator.language.slice(0, 2);
    const isRtl = rtlLanguages.includes(lang);
    let param = 'de';
    if (languages.includes(lang)) {
      param = lang;
    } else if (arLanguages.includes(lang)) {
      param = 'ar';
    }
    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }
    i18n.locale = param;
    store.commit('SET_LOCALE', param);

    next({ name: 'home', params: { lang: param } });
  } else if (from.params.lang !== to.params.lang) {
    const { lang } = to.params;
    document.documentElement.lang = lang;
    const isRtl = arLanguages.includes(lang);

    i18n.locale = lang;
    store.commit('SET_LOCALE', lang);

    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }

    next();
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
