import Vue from 'vue';
import Vuex, {
  ActionContext,
  ActionTree,
  GetterTree,
  ModuleTree,
  MutationTree,
} from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import moduleStore, {
  api, handleErrorResponse, ModuleMutations, ModuleState,
} from 'common-modules/src/store/moduleStore';
import { ExtendedRunConcentration } from '@/store/interface/Concentration';
import { DiscussionRoot, DiscussionPost } from '@/store/interface/Discussion';
import { ExtendedCourse } from '@/store/interface/Course';

Vue.use(Vuex);

export interface LearningState {
  concentration: ExtendedRunConcentration | null;
  coursePreviews: ExtendedCourse[] | null;
  discussion: DiscussionRoot | null;
  locale: string;
}

export const apiStatus = {
  STATUS_ERROR: 'error',
  STATUS_IDLE: 'idle',
  STATUS_FETCHING: 'fetching',
  STATUS_SUCCESS: 'success',
};

const learningState: LearningState = {
  concentration: null,
  coursePreviews: [],
  discussion: null,
  locale: 'en',
};

const getters: GetterTree<LearningState, LearningState> = {
  api: () => api,
  concentration: (state) => state.concentration,
  coursePreviews: (state) => state.coursePreviews,
  discussion: (state) => state.discussion,
  locale: (state) => state.locale,
};

type LearningMutations<S = LearningState> = {
  SET_CONCENTRATION(state: S, payload: ExtendedRunConcentration): void;
  SET_LOCALE(state: S, payload: string): void;
  SET_COURSE_PREVIEWS(state: S, payload: ExtendedCourse[] | null): void;
  SET_DISCUSSION(state: S, payload: DiscussionRoot): void;
  ADD_COMMENT(state: S, payload: DiscussionPost): void;
  HANDLE_LOGIN(state: S, payload: { token: string, refreshToken: string }): void;
}

const mutations: MutationTree<LearningState> & LearningMutations = {
  SET_CONCENTRATION (state, data) {
    state.concentration = data;
  },
  SET_LOCALE (state, locale) {
    state.locale = locale;
  },
  SET_COURSE_PREVIEWS (state, data) {
    state.coursePreviews = data;
  },
  SET_DISCUSSION (state, data) {
    state.discussion = data;
  },
  ADD_COMMENT (state, data) {
    state.discussion?.posts.push(data);
  },
  HANDLE_LOGIN (state, data) {
    api.login(data.token, data.refreshToken);
  },
};

type unitedActions = LearningMutations & ModuleMutations;

type AugmentedActionContext = {
  commit<K extends keyof unitedActions>(
    key: K,
    payload: Parameters<unitedActions[K]>[1]
  ): ReturnType<unitedActions[K]>;
} & Omit<ActionContext<LearningState, LearningState>, 'commit'>;

const actions: ActionTree<LearningState, LearningState> = {
  getData<T = any> ({ state }: AugmentedActionContext, url: string) {
    return new Promise((resolve, reject) => {
      api.axios.get(`/api/${state.locale}/${url}`)
        .then((response: AxiosResponse<T>) => {
          resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  postData<T = any> ({ state }: AugmentedActionContext, { url, formData }: { url: string, formData: FormData }) {
    return new Promise((resolve, reject) => {
      api.axios({
        method: 'post',
        url: `/api/${state.locale}/${url}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: AxiosResponse<T>) => {
        resolve(response.data);
      }).catch((e: AxiosError) => {
        reject(handleErrorResponse(e));
      });
    });
  },
  postEmptyData ({ state }: AugmentedActionContext, url: string) {
    return new Promise((resolve, reject) => {
      api.axios.post(`/api/${state.locale}/${url}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  commitWbt ({ state }: AugmentedActionContext, {
    unitCode,
    wbtIndex,
    aiccData,
    additional,
  }) {
    return new Promise<void>((resolve, reject) => {
      const additionalUrl = additional ? `/${additional}` : '';
      const postUrl = `/api/${state.locale}/wbt/${unitCode}/${wbtIndex}${additionalUrl}`;
      api.axios.post(postUrl, aiccData)
        .then(() => {
          resolve();
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  publishGrade ({ state }: AugmentedActionContext, { concentrationCode, unitCode, lmsId }: { concentrationCode: string, unitCode: string, lmsId: string }) {
    return new Promise((resolve, reject) => {
      api.axios.post(`/api/${state.locale}/gradebook/${concentrationCode}/publish/${unitCode}/${lmsId}`)
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  getCoursePreviews ({ state, commit }: AugmentedActionContext) {
    return new Promise<void>((resolve, reject) => {
      api.axios.get(`/api/${state.locale}/courses`)
        .then((response: AxiosResponse) => {
          commit('SET_COURSE_PREVIEWS', response.data);
          resolve();
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
  publishTask<T = any> ({ state }: AugmentedActionContext, taskCode: string) {
    return new Promise((resolve, reject) => {
      api.axios.post(`/api/${state.locale}/task/${taskCode}/publish`)
        .then((response: AxiosResponse<T>) => {
          resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleErrorResponse(e));
        });
    });
  },
};

const modules: ModuleTree<ModuleState> = {
  base: moduleStore,
};

export default new Vuex.Store<LearningState>({
  state: learningState,
  getters,
  mutations,
  actions,
  modules,
});
