








import Vue from 'vue';
import { mapGetters } from 'vuex';
import Component from 'vue-class-component';
import CommonHeader from 'common-modules/src/components/CommonHeader.vue';
import CommonFooter from 'common-modules/src/components/CommonFooter.vue';
import { UserInterface } from 'common-modules/src/store/interface/User';

const ALLOWED_PATHS = ['login', 'handle_login', 'public_wbt'];
const REDIRECT_URL = 'REDIRECT_URL';

@Component({
  components: {
    CommonHeader,
    CommonFooter,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class App extends Vue {
  currentUser!: UserInterface;

  handleLoginRedirect (): void {
    const fullPath = localStorage.getItem(REDIRECT_URL);
    localStorage.removeItem(REDIRECT_URL);
    if (fullPath) {
      this.$router.push(fullPath);
    } else {
      this.$router.push({
        name: 'home',
      });
    }
  }

  get showHeader (): boolean {
    const currentRoute = this.$route;
    return !!currentRoute
      && !!currentRoute.name
      && !ALLOWED_PATHS.includes(currentRoute.name)
      && !!this.currentUser;
  }

  get showView (): boolean {
    return !!this.currentUser
      || (this.$route.name && ALLOWED_PATHS.includes(this.$route.name));
  }

  mounted (): void {
    this.$store.dispatch('getBaseData', false)
      .then()
      .catch(() => {
        if (this.$route.name && !ALLOWED_PATHS.includes(this.$route.name)) {
          localStorage.setItem(REDIRECT_URL, this.$route.fullPath);
          this.$router.push({
            name: 'login',
          });
        }
      });
  }
}
