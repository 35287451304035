import { render, staticRenderFns } from "./CommonProfile.vue?vue&type=template&id=42d31feb&lang=pug&"
import script from "./CommonProfile.vue?vue&type=script&lang=js&"
export * from "./CommonProfile.vue?vue&type=script&lang=js&"
import style0 from "./CommonProfile.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../lms/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports